import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import Img1 from './assets/img1.png'
import Stars from './assets/stars.svg'

const data = [
    {
        text: "As a manufacturer, our partnership with retailers is vital. PromoRama has bridged the gap between our production line and the store shelves with real-time tracking that’s second to none. We've seen a 25% increase in efficiency and a significant boost in our sales figures since we started using PromoRama. It's like having a command center for all our sales activities!",
        image: Img1,
        name: "Sarah Jay",
        role: "Manufacturer"
    },
    {
        text: "As a manufacturer, our partnership with retailers is vital. PromoRama has bridged the gap between our production line and the store shelves with real-time tracking that’s second to none. We've seen a 25% increase in efficiency and a significant boost in our sales figures since we started using PromoRama. It's like having a command center for all our sales activities!",
        image: Img1,
        name: "Admil Bron",
        role: "Promoter"
    },
    {
        text: "As a manufacturer, our partnership with retailers is vital. PromoRama has bridged the gap between our production line and the store shelves with real-time tracking that’s second to none. We've seen a 25% increase in efficiency and a significant boost in our sales figures since we started using PromoRama. It's like having a command center for all our sales activities!",
        image: Img1,
        name: "Waheed Ceejay",
        role: "Retailer"
    },
    {
        text: "As a manufacturer, our partnership with retailers is vital. PromoRama has bridged the gap between our production line and the store shelves with real-time tracking that’s second to none. We've seen a 25% increase in efficiency and a significant boost in our sales figures since we started using PromoRama. It's like having a command center for all our sales activities!",
        image: Img1,
        name: "Emlon Banks",
        role: "Store owner"
    },
    {
        text: "As a manufacturer, our partnership with retailers is vital. PromoRama has bridged the gap between our production line and the store shelves with real-time tracking that’s second to none. We've seen a 25% increase in efficiency and a significant boost in our sales figures since we started using PromoRama. It's like having a command center for all our sales activities!",
        image: Img1,
        name: "Ebenezer Maliu",
        role: "Supervisor"
    },
    {
        text: "As a manufacturer, our partnership with retailers is vital. PromoRama has bridged the gap between our production line and the store shelves with real-time tracking that’s second to none. We've seen a 25% increase in efficiency and a significant boost in our sales figures since we started using PromoRama. It's like having a command center for all our sales activities!",
        image: Img1,
        name: "Ahmed Bass",
        role: "Sales Rep."
    },
]

const Testimonials = () => {
  return (
    <Box px={"1rem"} maxW="1200px" mx="auto" mt={["4rem", "8rem"]}>
        <Heading fontWeight={["500", "600"]} mb="4rem" maxW={["270px", "100%"]} mx={"auto"} textAlign={"center"} color={"#1D2939"} fontSize={["1.5rem", "1.8rem"]}>Here&apos;s what our customers think</Heading>
        <Flex gap={"1rem"} overflowX={"scroll"}
        sx={
            { 
            '::-webkit-scrollbar':{
                  display:'none'
              }
            }
        }
        >
            {data?.map(({name, text, image, role}, index) => {
                return (
                    <Box flex={"1 0 265px"} key={index} padding={"1.2rem"} bg="#FFF"borderRadius={"8px"} border="1px solid #FFCBCB">
                        <Image src={Stars} />
                        <Text mt="1.2rem" maxW={"95%"} fontSize={".75rem"} lineHeight={"16px"}>{text}</Text>
                        <Flex mt="1.5rem" alignItems={"center"}>
                            <Box height={"40px"} width={"40px"} backgroundImage={image} backgroundSize={"cover"} mr=".5rem" />
                            <Box>
                                <Text fontSize={".78rem"} color="#101928" fontWeight={"600"}>{name}</Text>
                                <Text fontSize={".78rem"} color="#344054">{role}</Text>
                            </Box>
                        </Flex>
                    </Box>
                )
            })}
        </Flex>
    </Box>
  )
}

export default Testimonials