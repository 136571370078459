import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import Bg1 from './assets/bg1.png'
import Mask from '../../../../assets/gridMask.svg'
import ClientForm from '../../../../components/clientForm/clientForm'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import { useState } from 'react'

const Banner = () => {
  const [triggerClose, setTriggerClose] = useState("")
  return (
    <Box bg="#000" pt={["4rem", "8rem"]} pl={["1rem", "5rem"]} mt="8rem" overflow={"hidden"}>
        <Flex position={"relative"} flexWrap={"wrap"} gap={["4rem", ""]}>
            <Box zIndex={"2"} flex={"1"}>
                <Heading fontWeight={"500 !important"} maxW={["300px", "440px"]} color={"#F9FAFB"} lineHeight={["40px", "50px"]} fontSize={["1.9rem", "2.5rem"]}>Connect, track and amplify your sales operations in real time</Heading>
                <Text mt="1rem" maxW={"370px"} fontSize={"1rem"} color="#EAECF0">A dashboard for all your activities. Know what you sell at every point in time.</Text>
                <CustomDrawer
                position={"right"}
                title={"Get started with PromoRama"}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={
                  <PrimaryButton mt="4.5rem" width="150px" bg="#FFF" border="1px solid #D0D5DD" py=".8rem" height="43px !important" color="#000000 !important" text="Get Started" />
                }
                content={
                    <ClientForm setTriggerClose={setTriggerClose} />
                }
                />
            </Box>
            <Box zIndex={"2"} flex={"1"} height={"600px"} minWidth={"350px"} backgroundImage={Bg1} backgroundSize={"cover"} />
            <Image src={Mask} transform={"scale(.8)"} position={"absolute"} zIndex={"1"} left={"10%"} />
        </Flex>
        <Box position={"absolute"} height={["0", "50px"]} left={"0"} right={"0"} bg="#1C1A1A" />
    </Box>
  )
}

export default Banner