import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import Noise from './assets/noise.svg'
import { useState } from "react"
import { HiCheck } from "react-icons/hi"
import PrimaryButton from "../../../../components/primaryButton/primarybutton"

const plans = [
    {
        pricePerMonth: "",
        pricePerYear: "",
        title: "Free Trial",
        subtitle: "One person",
        features: [
            "Document Management",
            "Authorization Tracking",
            "Secure Cloud",
            "Notification System"
        ]
    },
    {
        pricePerMonth: 500,
        pricePerYear: 5500,
        title: "Small Enterprise",
        subtitle: "1-50 people",
        features: [
            "Document Management",
            "Authorization Tracking",
            "Secure Cloud",
            "Notification System"
        ]
    },
    {
        pricePerMonth: 2500,
        pricePerYear: 27500,
        title: "Large Enterprise",
        subtitle: "50+ people",
        features: [
            "Document Management",
            "Authorization Tracking",
            "Secure Cloud",
            "Notification System"
        ]
    }
]


const Pricing = () => {
  const [period, setPeriod] = useState("monthly")

  const handleToggle = () => {
    if(period === "monthly") {
        setPeriod("yearly")
    } else if(period === "yearly") {
        setPeriod("monthly")
    } else return;
  }

  return (
    <Box px={["1rem", "3rem", "6rem"]} bg="#000000" mt={["4rem", "8rem"]} py={["3rem", "5rem"]}>
        <Heading fontWeight={"600"} mb="3rem" textAlign={"center"} color={"#FCFCFD"} fontSize={["1.5rem", "1.7rem"]}>Pricing</Heading>
        <Box bg="#1C1D1F" p={["2rem 1.5rem 3rem 1.5rem", "4rem 5rem 5rem 5rem"]} backgroundImage={Noise} backgroundSize={"cover"} border={"1px solid #D0D5DD"} borderRadius={"24px"}>
            <Flex alignItems={"center"} mb="1.7rem" padding={".53rem 1.5rem"} gap="1rem" bg="#131313" width={"fit-content"} mx="auto" borderRadius={"56px"} border=".5px solid #667085">
                <Text transition={"250ms ease"} cursor={"pointer"} onClick={() => setPeriod("monthly")} fontWeight={period === "monthly" ? "500" : "400"} fontSize={[".73rem", ".87rem"]} color={period === "monthly" ? "#F2F4F7" : "#98A2B3"}>MONTHLY</Text>
                
                <Box cursor={"pointer"} onClick={handleToggle} position={"relative"} width={["36px", "46px"]} height={"14px"} bg="#404040" borderRadius={"20px"}>
                    <Box position={"absolute"} transition={"250ms ease"} top="-15%" right={period === "monthly" ? "50%" : "-10%"} height={"18px"} width={"18px"} boxSizing="border-box" borderRadius={"50%"} bg="#FFF" border="1.5px solid #FF4105">
                        <Box width={"60%"} height={"60%"} m="auto" mt=".21rem" bg="#FF4105" borderRadius={"50%"} />
                    </Box>
                </Box>

                <Text transition={"250ms ease"} cursor={"pointer"} onClick={() => setPeriod("yearly")} fontWeight={period === "yearly" ? "500" : "400"} fontSize={[".73rem", ".87rem"]} color={period === "yearly" ? "#F2F4F7" : "#98A2B3"}>YEARLY</Text>
            </Flex>

            <Flex gap={["1.5rem", "2.5rem"]} mt="3rem" overflowX={"scroll"}
            sx={
                { 
                '::-webkit-scrollbar':{
                      display:'none'
                  }
                }
            }
            >
                {plans?.map(({title, subtitle, features, pricePerMonth, pricePerYear}) => {
                    return (
                        <Box flex={"1 0 265px"} boxShadow={"0px 80px 120px 0px #102A431A"} key={title} padding={"1.2rem"} bg="#FFF" borderRadius={"8px"} border=".3px solid #EAECF0">
                            <Flex justifyContent={"flex-end"}>
                                <Text opacity={pricePerMonth ? "1" : "0"} fontSize={"1.7rem"} fontWeight={"500"} color={"#667085"}>${period === "monthly" ? pricePerMonth?.toLocaleString() : pricePerYear?.toLocaleString()}/<span style={{fontSize: ".85rem", color: "#667085"}}>{period === "monthly" ? "mo" : "yr"}</span></Text>
                            </Flex>
                            <Box mt="1rem">
                                <Text fontSize={".8rem"} color={"#959595"}>{subtitle}</Text>
                                <Text fontSize={"1.3rem"} color="#4C4C4C" fontWeight={"500"}>{title}</Text>
                            </Box>
                            <Box mt="1.3rem">
                                {features?.map(i => {
                                    return (
                                        <Text mb=".9rem" fontSize={".8rem"} color="#4C4C4C" key={i}><HiCheck style={{display: "inline", transform: "translateY(2px)", color: "#FF4105"}} /> {i}</Text>
                                    )
                                })}
                            </Box>
                            <PrimaryButton mt="2.5rem" width="100%" bg="#000" border="1px solid #D0D5DD" py=".8rem" height="48px !important" color="#FFF !important" text="Choose this plan" />
                        </Box>
                    )
                })}
            </Flex>
        </Box>
    </Box>
  )
}

export default Pricing

