import React, { useState } from 'react'
import CustomInput from '../customInput/customInput'
import { Flex, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react';
import PrimaryButton from '../primaryButton/primarybutton';
import axios from 'axios';

const ClientForm = ({setTriggerClose}) => {
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [contactPersonPhoneNumber, setContactPersonPhoneNumber] = useState("");
  const [proposedUsersSize, setProposedUsersSize] = useState("");

  const [loading, setLoading] = useState(false);
  const toast = useToast()


  const submitForm = async(e) => {
    e.preventDefault()
    setLoading(true)

    const datax = {
      companyName,
      companyEmail,
      companyPhoneNumber: companyPhoneNumber.charAt(0) === "0"
      ? "+234" + companyPhoneNumber.slice(1)
      : "+234" + companyPhoneNumber,
      companyLocation,
      contactPersonName,
      contactPersonEmail,
      contactPersonPhoneNumber: contactPersonPhoneNumber.charAt(0) === "0"
      ? "+234" + contactPersonPhoneNumber.slice(1)
      : "+234" + contactPersonPhoneNumber,
      proposedUsersSize
    }

    try {
      const {data} = await axios.post('https://promorama-api.onrender.com/api/v1/support/send-client-info', datax);
      setTriggerClose('close')
      toast({
        description: data?.message,
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
    <form onSubmit={submitForm}>
      <CustomInput
        placeholder={"Name"}
        type="text"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        required
      />

      <Flex gap={".8rem"}>
        <CustomInput
          placeholder={"Company Email Address"}
          type="email"
          value={companyEmail}
          onChange={(e) => setCompanyEmail(e.target.value)}
          required
        />

        <CustomInput
          placeholder={"Company Phone Number"}
          type="tel"
          value={companyPhoneNumber}
          onChange={(e) => setCompanyPhoneNumber(e.target.value)}
          required
          minLength={11}
          maxLength={11}
        />
      </Flex>

      <CustomInput
        placeholder={"Company Location"}
        type="text"
        value={companyLocation}
        onChange={(e) => setCompanyLocation(e.target.value)}
        required
      />

      <CustomInput
        placeholder={"Contact Person Name"}
        type="text"
        value={contactPersonName}
        onChange={(e) => setContactPersonName(e.target.value)}
        required
      />

      <Flex gap={".8rem"}>
        <CustomInput
          placeholder={"Contact Person Email"}
          type="email"
          value={contactPersonEmail}
          onChange={(e) => setContactPersonEmail(e.target.value)}
          required
        />

        <CustomInput
          placeholder={"Contact Person Phone Number"}
          type="tel"
          value={contactPersonPhoneNumber}
          onChange={(e) => setContactPersonPhoneNumber(e.target.value)}
          required
          minLength={11}
          maxLength={11}
        />
      </Flex>

      <RadioGroup onChange={setProposedUsersSize} value={proposedUsersSize} required>
        <Text mt="1rem" fontSize={".9rem"} color={"#71717A"}>Size of proposed users</Text>
        <Stack color={"#71717A"} m=".7rem" direction='column'>
          <Radio value='Less than 10'>Less than 10</Radio>
          <Radio value='10 - 50'>10 - 50</Radio>
          <Radio value='50 - 200'>50 - 200</Radio>
          <Radio value='200+'>200+</Radio>
        </Stack>
      </RadioGroup>
      
      <PrimaryButton mt="3rem" type="submit" text="Submit" loading={loading} />
      
    </form>
    </>
  )
}

export default ClientForm