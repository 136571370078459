import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import Img1 from '../../assets/userImages/img1.png'
import { useNavigate } from 'react-router-dom'
import Img2 from '../../assets/userImages/img2.png'
import Img3 from '../../assets/userImages/img3.png'
import Img4 from '../../assets/userImages/img4.png'
import PrimaryButton from '../../components/primaryButton/primarybutton'
import Img5 from '../landingPage/components/how/assets/img1.png'

const Promoters = () => {
  const navigate = useNavigate()
  return (
    <Box px={["1rem", "2rem", "4rem", "6rem"]} py={["2rem", "3rem"]} pt="100px">
        <HiArrowNarrowLeft cursor={"pointer"} onClick={() => navigate(-1)} />
        <Heading mt="1rem" fontWeight={"500"} color={"#344054"} fontSize={["1.35rem", "1.7rem"]}>Promoters</Heading>
        <Text color="#667185" maxW={["100%", "450px"]} fontSize={[".85rem", ".95rem"]} mt="1rem" lineHeight={"22px"}>These are the individuals that are present at the shops and help convince people to buy the products</Text>
        <Box position={"relative"}>
            <Flex gap={["1rem", "1.5rem"]} mt={["1.7rem", "2.2rem"]} overflowX={"scroll"}
            sx={
                { 
                '::-webkit-scrollbar':{
                      display:'none'
                  }
                }
            }
            >
                <Box bg="lightgray" backgroundImage={Img1} backgroundSize={"cover"} borderRadius={"12px"} height={["160px","230px"]} flex={["1 0 170px", "1 0 260px"]}/>
                <Box bg="lightgray" backgroundImage={Img2} backgroundSize={"cover"} borderRadius={"12px"} height={["160px","230px"]} flex={["1 0 170px", "1 0 260px"]}/>
                <Box bg="lightgray" backgroundImage={Img3} backgroundSize={"cover"} borderRadius={"12px"} height={["160px","230px"]} flex={["1 0 170px", "1 0 260px"]}/>
                <Box bg="lightgray" backgroundImage={Img4} backgroundSize={"cover"} borderRadius={"12px"} height={["160px","230px"]} flex={["1 0 170px", "1 0 260px"]}/>
            </Flex>

            <Flex gap="3rem" mt="4rem" flexWrap={"wrap"}>
                <Box flex="1 1 350px" bg="#FFF" borderRadius={"12px"} height={"590px"} backgroundImage={Img5} backgroundSize={"cover"} />
                <Box flex={"1 1 350px"} height={"580px"}>
                    <Heading fontWeight={"500 !important"} maxW={"370px"} color={"#344054"} lineHeight={"30px"} fontSize={["1.2rem", "1.5rem"]}>The promoter does the following</Heading>
                    <Text maxW="350px" mt=".4rem" fontSize={[".85rem", ".95rem"]} color="#667085">Subtitle about the promoter</Text>
                    <Text maxW="500px" bg="#FFF" padding={"1rem 1.5rem"} mt="2rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>Promoters use the mobile/web app to track and record sales data for the products they promote.</Text>
                    <Text maxW="500px" bg="#FFF" padding={"1rem 1.5rem"} mt="1rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>Utilize the task management feature to view and update their daily tasks.</Text>
                    <Text mb="4rem" maxW="500px" bg="#FFF" padding={"1rem 1.5rem"} mt="1rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>Actively participate in ongoing initiatives defined by the admin</Text>
                    <a href="https://app.thepromorama.com" target="_blank" rel="noreferrer">
                    <PrimaryButton mt="1.5rem" bg="#000000" py=".8rem" width="165px" height="53px !important" color="#FFF !important" text="Login" />
                    </a>
                </Box>
            </Flex>
        </Box>
    </Box>
  )
}

export default Promoters