import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import Icon1 from './assets/icon1.svg'
import Illustration from './assets/illu2.png'
import './hero.css'
import PrimaryButton from "../../../../components/primaryButton/primarybutton"
import Mask from '../../../../assets/gridMask.svg'
import CustomDrawer from "../../../../components/customDrawer/customDrawer"
import ClientForm from "../../../../components/clientForm/clientForm"
import { useState } from "react"

const Hero = () => {
  const [triggerClose, setTriggerClose] = useState("")
  return (
    <Box height={["99vh", "120vh"]} bg="#0C0C0C" overflowY={"hidden"} px="1rem" pt="75px">
        <Box pt="4.2rem" textAlign={"center"}>
            
            <Box pb="3.2rem" zIndex={"2"} overflowY={"hidden"} position={"relative"}>
                <Image zIndex={"1"} opacity={".3"} src={Mask} left={"24%"} position={"absolute"} />
                <Flex zIndex={"2"} mb="1.7rem" padding={[".53rem 1.1rem", ".53rem 1.5rem"]} gap=".5rem" bg="#131313" width={"fit-content"} mx="auto" borderRadius={"56px"} border=".5px solid #667085">
                    <Text fontSize={[".7rem", ".85rem"]} color="#D0D5DD">NO.1 SALES MONITORING TOOL</Text>
                    <Image src={Icon1} />
                </Flex>
                <Box mb="1.9rem" maxW={["350px", "700px"]} mx="auto">
                    <Heading fontWeight={"600"} display={"inline"} className="gradient-text" lineHeight={["36px", "45px", "70px"]} fontSize={["1.8rem", "2.5rem", "3.5rem"]}>Elevate your sales, promotions, and field monitoring experience</Heading><Text display={"inline-block"} transform={["scale(1.4) translate(6px, -4px)", "scale(3) translate(9px, -6px)"]}>📈</Text>
                </Box>
                <Text mb="1.9rem" maxW={["300px", "100%"]} mx="auto" fontSize={[".9rem", "1rem"]} color="#EAECF0">Unify Your Sales Ecosystem with real-time Insights and Connectivity</Text>
                <CustomDrawer
                position={"right"}
                title={"Get started with PromoRama"}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={
                    <PrimaryButton width="150px" bg="#FFF"  zIndex={"2"} py=".8rem" height="53px !important" color="#101828 !important" text="Get Started" />
                }
                content={
                    <ClientForm setTriggerClose={setTriggerClose} />
                }
                />
                <Text mt="1.9rem" fontSize={[".68rem", ".78rem"]} color="#EAECF0">Get a free 7-day trial of premium features | No credit card required</Text>
            </Box>

            <Box maxW={"1150px"} zIndex={"4 !important"} borderRadius={"16px 16px 0 0"} bg="#FFF" mx="auto" height={"400px"} backgroundImage={Illustration} backgroundSize={"cover"} />
        </Box>
    </Box>
  )
}

export default Hero;