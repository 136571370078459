import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react'
import Core from '../../../../components/core/core'
import About1 from './assets/about1.png'
import About2 from './assets/about2.png'
import About3 from './assets/about3.png'
import About4 from './assets/about4.png'

const About = () => {
  return (
    <Box px="1rem">
        <Box mt={["3.5rem", "5rem"]} textAlign={"center"}>
            <Heading fontWeight={"500 !important"} maxW={["300px", "600px"]} mx="auto" color={"#344054"} lineHeight={["32px", "45px"]} fontSize={["1.6rem", "2.3rem"]}>Revolutionising sales, promotions and field monitoring</Heading>
            <Text mb="1.9rem" mt="1rem" fontSize={"1rem"} mx="auto" maxW={["350px", "100%"]} color="#667085">Seamlessly linking manufacturers, storefronts, sales teams, and enumerators.</Text>
            <Flex justifyContent={"center"}>
                <Core alt={""} />
            </Flex>
        </Box>

        <Box px="1rem">
            <Box mt="6rem" px={["1.5rem", "2.5rem"]} borderRadius={"26px"} minHeight={"580px"} maxWidth={"970px"} mx="auto" background={"linear-gradient(109.75deg, #FFE6E6 0%, rgba(235, 231, 255, 0.7) 100%)"}>
                <Grid transform={"translateY(-2.67rem)"} templateColumns={['repeat(auto-fill, minmax(150px, 1fr))', 'repeat(auto-fill, minmax(250px, 1fr))', 'repeat(auto-fill, minmax(350px, 1fr))']} gap={"2.5rem"}>
                    <Box boxShadow={"0px 4px 24px 0px #0000001A"} borderRadius={"8px"} height={"310px"} bg="#FFF" backgroundImage={About1} backgroundSize={["contain", "cover"]}></Box>
                    <Box boxShadow={"0px 4px 24px 0px #0000001A"} borderRadius={"8px"} height={"310px"} bg="#FFF" backgroundImage={About2} backgroundSize={["contain", "cover"]}></Box>
                    <Box boxShadow={"0px 4px 24px 0px #0000001A"} borderRadius={"8px"} height={"310px"} bg="#FFF" backgroundImage={About3} backgroundSize={["contain", "cover"]}></Box>
                    <Box boxShadow={"0px 4px 24px 0px #0000001A"} borderRadius={"8px"} height={"310px"} bg="#FFF" backgroundImage={About4} backgroundSize={["contain", "cover"]}></Box>
                </Grid>
            </Box>
        </Box>

    </Box>
  )
}

export default About;
