import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'

const Privacy = () => {
  return (
    <Box px={["1rem", "3rem", "6rem"]} py="3rem" pt="75px">
        <Heading mt="1rem" fontWeight={"500"} color={"#344054"} fontSize={["1.35rem", "1.7rem"]}>Privacy Policy</Heading>
        <Text color="#667185" fontSize={[".85rem", ".95rem"]} mt="1rem" lineHeight={"22px"}>Welcome to Promorama! At Promorama, we prioritize your privacy and are dedicated to safeguarding your personal information. This privacy policy outlines how we collect, use, and share your data when you engage with our app.</Text>

        <Box mt="2.5rem">
            <Heading fontWeight={"500"} color={"#344054"} fontSize={["1rem", "1.3rem"]}>Information We Collect</Heading>
            <Text color="#667185" fontSize={[".85rem", ".95rem"]} mt=".7rem" lineHeight={"22px"}>When you use Promorama, we collect specific information that you willingly provide, including your name, email address, and any additional personal details you choose to share. Promorama also incorporates background location tracking on your device, a feature designed to enhance your experience. It's important to note that your location data never leaves your phone unless explicitly shared temporarily for specific app functionalities.</Text>
        </Box>
        <Box mt="2.5rem">
            <Heading fontWeight={"500"} color={"#344054"} fontSize={["1rem", "1.3rem"]}>How We Use Your Information</Heading>
            <Text color="#667185" fontSize={[".85rem", ".95rem"]} mt=".7rem" lineHeight={"22px"}>Promorama utilizes the information we gather to enhance your app experience. This includes personalizing your interactions, facilitating seamless transactions between users, and optimizing our services based on your usage patterns. Background location tracking is employed to provide features tailored to your geographical context, ensuring a more relevant and efficient experience.</Text>
        </Box>
        <Box mt="2.5rem">
            <Heading fontWeight={"500"} color={"#344054"} fontSize={["1rem", "1.3rem"]}>Sharing Your Information</Heading>
            <Text color="#667185" fontSize={[".85rem", ".95rem"]} mt=".7rem" lineHeight={"22px"}>Your privacy is paramount at Promorama. We never share your personal information with third parties for their marketing endeavors. In certain instances, we may share data with third-party service providers crucial to delivering Promorama’s services, ensuring they align with our stringent privacy standards. Background location data shared temporarily is done so with your explicit consent for specific app features.</Text>
        </Box>
        <Box mt="2.5rem">
            <Heading fontWeight={"500"} color={"#344054"} fontSize={["1rem", "1.3rem"]}>Your Choices and Access to Your Information</Heading>
            <Text color="#667185" fontSize={[".85rem", ".95rem"]} mt=".7rem" lineHeight={"22px"}>Promorama puts you in control. We respect your decisions and will not share your personal information for third-party marketing purposes. While we may share information with third-party service providers assisting us in delivering our services, we prioritize transparency and protection throughout this process. You have the ability to manage and control the sharing of your background location data within the app settings.</Text>
        </Box>
        <Box mt="2.5rem">
            <Heading fontWeight={"500"} color={"#344054"} fontSize={["1rem", "1.3rem"]}>Security Measures</Heading>
            <Text color="#667185" fontSize={[".85rem", ".95rem"]} mt=".7rem" lineHeight={"22px"}>Promorama employs robust security measures to safeguard your data from unauthorized access, disclosure, alteration, or destruction. Your trust is of the utmost importance to us, and we are committed to maintaining the confidentiality and integrity of your information.</Text>
        </Box>
        <Box mt="2.5rem">
            <Heading fontWeight={"500"} color={"#344054"} fontSize={["1rem", "1.3rem"]}>Updates to Our Privacy Policy</Heading>
            <Text color="#667185" fontSize={[".85rem", ".95rem"]} mt=".7rem" lineHeight={"22px"}>As Promorama evolves, so may our privacy practices. We encourage you to periodically review our privacy policy for any updates. We will notify you of any material changes, ensuring you stay informed and comfortable with how your data is handled.</Text>
        </Box>
        <Box mt="2.5rem">
            <Heading fontWeight={"500"} color={"#344054"} fontSize={["1rem", "1.3rem"]}>Contact Us</Heading>
            <Text color="#667185" fontSize={[".85rem", ".95rem"]} mt=".7rem" lineHeight={"22px"}>If you have any questions, concerns, or feedback regarding our privacy policy, please don’t hesitate to reach out to us at 
            <a href="mailto:info@thepromorama.com" target="_blank" rel="noopener noreferrer"> <span style={{color: "#FF4105", cursor: "pointer", textDecoration: "underline"}}>info@thepromorama.com</span></a>
            </Text>
        </Box>
    </Box>
  )
}

export default Privacy;