import { Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import Footer from './components/footer/footer'
import NavBar from './components/navbar/navBar'
import LandingPage from './pages/landingPage/landingPage'
import ContactUs from './pages/contactUs/contactUs'
import Support from './pages/support/support'
import Promoters from './pages/promoters/promoters'
import { useEffect } from 'react'
import Supervisors from './pages/supervisors/supervisors'
import StoreManager from './pages/storeManager/storeManager'
import Privacy from './pages/privacy/privacy'

function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className='App'>
      <NavBar />
      <Routes>
          <Route path='/'  element={<LandingPage />} />
          <Route path='/contact'  element={<ContactUs />} />
          <Route path='/support'  element={<Support />} />
          <Route path='/promoters'  element={<Promoters />} />
          <Route path='/supervisors'  element={<Supervisors />} />
          <Route path='/store-managers'  element={<StoreManager />} />
          <Route path='/privacy-policy'  element={<Privacy />} />
        </Routes>
      <Footer />
    </div>
  )
}

export default App
