import { Box, Flex, Image, Input, Text } from '@chakra-ui/react'
import Logo from '../../assets/logo.svg'
import PrimaryButton from '../primaryButton/primarybutton'
import { useNavigate } from 'react-router-dom'

const data = [
    {
        title: "Resources",
        links: [
            {
                name: "Learn Center",
                url: "#"
            },
            {
                name: "Support",
                url: "support"
            },
            {
                name: "Slack Community",
                url: "#"
            },
            {
                name: "Events",
                url: "#"
            },
            {
                name: "Cookies",
                url: "#"
            },
            {
                name: "Terms of Service",
                url: "#"
            },
            {
                name: "Privacy Policy",
                url: "privacy-policy"
            },
        ]
    },
    {
        title: "Product",
        links: [
            {
                name: "How it works",
                url: "#"
            },
            {
                name: "Features",
                url: "#"
            },
            {
                name: "Pricing",
                url: "#"
            },
            {
                name: "Tools & Integration",
                url: "#"
            },
            {
                name: "Start for Free",
                url: "#"
            }
        ]
    },
    {
        title: "About",
        links: [
            {
                name: "Our Story",
                url: "#"
            },
            {
                name: "Media Kit",
                url: "#"
            },
            {
                name: "Blog",
                url: "#"
            },
            {
                name: "Email Us",
                url: "#"
            },
        ]
    },
]

const Footer = () => {
  const navigate = useNavigate()
  return (
    <Box
      px={["1rem", "3rem", "6rem"]}
      bg="#000000"
      pb="3rem"
      pt="1.5rem"
    >
        <Box maxW={"1200px"} mx="auto" mt="7rem" pt="4rem" pb="4rem" borderTop={".4px solid #344054"} borderBottom={".4px solid #344054"}>
            <Flex gap="2rem" flexWrap={"wrap"}>
                <Flex flex={"4 0 350px"} gap={"1rem"} flexWrap={"wrap"}>
                    {data?.map(({title, links}) => (
                        <Box flex={"1 1 170px"} key={title} textAlign={"left"}>
                            <Text fontSize={".87rem"} mb="1.2rem" color="#98A2B3">{title}</Text>
                            {links?.map(({name, url}) => (
                                <Text key={name} onClick={() => navigate(`/${url}`)} fontSize={".87rem"} transition={"250ms ease"} _hover={{color: "#FF4105"}} fontWeight={"500"} cursor={"pointer"} mb=".7rem" color="#FCFCFD">{name}</Text>
                            ))}
                        </Box>
                    ))}
                    
                </Flex>
                <Box flex={"2 0 300px"}>
                    <Image src={Logo} />
                    <Text mt="1.5rem" fontSize={".83rem"} mb="1.2rem" color="#344054">Get the latest updates about promorama, new features and product updates.</Text>
                    <Flex gap=".6rem" mt="2rem">
                        <Input placeholder='Email address' _placeholder={{fontSize: "13px", color: "#98A2B3"}} bg="#FFF" border="1px solid #D0D5DD" outline={"none"} _focus={{outline: "none", border: "none"}} height="48px !important" borderRadius="6px !important" />
                        <PrimaryButton width="125px" borderRadius="6px !important" bg="#FF4105" py=".8rem" height="48px !important" color="#FFF !important" text="Subscribe" />
                    </Flex>
                </Box>
            </Flex>
        </Box>

        <Flex justifyContent={"space-between"} mt="1.5rem" gap={["2rem", "auto"]} flexWrap={"wrap"}>
            <Flex alignItems={"center"} gap={"2rem"}>
                <Text cursor={"pointer"} fontSize={".83rem"} color="#667185">Terms of Service</Text>
                <Text cursor={"pointer"} fontSize={".83rem"} color="#667185">Privacy Policy</Text>
                <Text cursor={"pointer"} fontSize={".83rem"} color="#667185">Security</Text>
                <Text cursor={"pointer"} fontSize={".83rem"} color="#667185">Sitemap</Text>
            </Flex>
            <Box textAlign={["center", "right"]} width={["100%", "auto"]}>
                <Text cursor={"pointer"} fontSize={".83rem"}mb=".3rem" color="#667185">© 2023 Promorama. All rights reserved.</Text>
                <Text cursor={"pointer"} fontSize={".83rem"} color="#667185">PromoRama is owned by Connal Solutions LLC</Text>
            </Box>
        </Flex>
    </Box>
  )
}

export default Footer