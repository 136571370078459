import Download from "../../components/download/download"
import About from "./components/about/about"
import Banner from "./components/banner/banner"
import Faq from "./components/faq/faq"
import Features from "./components/features/features"
import Hero from "./components/hero/hero"
import How from "./components/how/how"
import Pricing from "./components/pricing/pricing"
import Testimonials from "./components/testimonials/testimonials"


const LandingPage = () => {
  return (
    <>
      <Hero />
      <About />
      <Features />
      <How />
      <Banner />
      <Testimonials />
      <Download />
      <Pricing />
      <Faq />
    </>
  )
}

export default LandingPage