import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import PrimaryButton from "../../../../components/primaryButton/primarybutton"
import Core from "../../../../components/core/core"
import Bg1 from './assets/bg1.png'
import Bg2 from './assets/bg2.png'
import Img1 from './assets/img1.png'
import Img2 from './assets/img2.png'
import Img3 from './assets/img3.png'
import Img4 from './assets/img4.png'
import Img5 from './assets/img5.png'
import Img6 from './assets/img6.png'
import { useState } from "react"
import ClientForm from "../../../../components/clientForm/clientForm"
import CustomDrawer from "../../../../components/customDrawer/customDrawer"


const Features = () => {
  const [triggerClose, setTriggerClose] = useState("")
  return (
    <Box px={"1rem"} maxW="1200px" mx="auto" mt={["5.5rem", "11rem"]}>
        <Box textAlign={"center"} mb="3.5rem">
            <Heading fontWeight={"500 !important"} maxW={"600px"} mx="auto" color={"#344054"} lineHeight={["32px", "45px"]} fontSize={["1.6rem", "2.3rem"]}>Features</Heading>
            <Text maxW="300px" mx="auto" mb="1.9rem" mt={[".5rem", ".8rem"]} fontSize={"1rem"} color="#667085">Here’s some of the cool stuff you can do with PromoRama</Text>
        </Box>

        <Flex gap="1rem" mb="1rem" flexWrap={"wrap"}>
            <Box flex={"4"} minW={"400px"} overflow={"hidden"} borderRadius={"12px"} height={"600px"} bg="#0E0E0E" padding={"4rem 0 0 4rem"}>
                <Box mb="2.5rem">
                    <Heading mb=".7rem" fontWeight={"600"} color={"#F9FAFB"} fontSize={"1.1rem"}>Attendance tracking</Heading>
                    <Text fontSize={".87rem"} maxW={["200px", "100%"]} color="#F2F4F7">Live Dashboards to Keep Your Finger on the Pulse</Text>
                </Box>
                <Box height={"450px"} bg="#FFF" backgroundImage={Img1} backgroundSize={"cover"} borderRadius={"12px 0 0 0"} />
            </Box>
            <Box flex={"3"} minW={"400px"} overflow={"hidden"} borderRadius={"12px"} height={"600px"} bg="#EEEEEE" padding={"4rem 0 0 4rem"}>
                <Box mb="2.5rem">
                    <Heading mb=".7rem" fontWeight={"600"} color={"#344054"} fontSize={"1.1rem"}>Task Management</Heading>
                    <Text fontSize={".87rem"} maxW={["200px", "100%"]} color="#475467">Monitor all promoter tasks</Text>
                </Box>
                <Box height={"450px"} bg="#FFF" backgroundImage={Img2} backgroundSize={"cover"} borderRadius={"12px 0 0 0"} />
            </Box>
        </Flex>

        <Flex gap="1rem" mb="1rem" flexWrap={"wrap"} direction={["column-reverse", "row"]}>
            <Box flex={"5"} minW={"400px"} overflow={"hidden"} borderRadius={"12px"} height={"600px"} bg="#EEEEEE" padding={"4rem 0 0 4rem"}>
                <Box mb="2.5rem">
                    <Heading mb=".7rem" fontWeight={"600"} color={"#344054"} fontSize={"1.1rem"}>Shelf & POSM Checks</Heading>
                    <Text fontSize={".87rem"} maxW={["200px", "100%"]} color="#475467">Ensure visual merchandising standards are met.</Text>
                </Box>
                <Box height={"450px"} bg="#FFF" backgroundImage={Img3} backgroundSize={"cover"} borderRadius={"12px 0 0 0"} />
            </Box>
            <Box flex={"3"} minW={"400px"} overflow={"hidden"} borderRadius={"12px"} height={"600px"} bg="#0E0E0E" padding={"4rem 0 0 4rem"}>
                <Box mb="2.5rem">
                    <Heading mb=".7rem" fontWeight={"600"} color={"#F9FAFB"} fontSize={"1.1rem"}>Sales Tracker</Heading>
                    <Text fontSize={".87rem"} maxW={["200px", "100%"]} color="#F2F4F7">Analyze sales by day, week, brand, store, and state.</Text>
                </Box>
                <Box height={"450px"} bg="#FFF" backgroundImage={Img4} backgroundSize={"cover"} borderRadius={"12px 0 0 0"} />
            </Box>
        </Flex>

        <Flex gap="1rem" flexWrap={"wrap"}>
            <Box flex={"3"} minW={"400px"} overflow={"hidden"} borderRadius={"12px"} height={"600px"} bg="#0E0E0E" padding={"4rem 0 0 4rem"}>
                <Box mb="2.5rem">
                    <Heading mb=".7rem" fontWeight={"600"} color={"#F9FAFB"} fontSize={"1.1rem"}>Route tracker</Heading>
                    <Text fontSize={".87rem"} maxW={["200px", "100%"]} color="#F2F4F7">View GPS map of routes and store stops.</Text>
                </Box>
                <Box height={"450px"} bg="#FFF" backgroundImage={Img5} backgroundSize={"cover"} borderRadius={"12px 0 0 0"} />
            </Box>
            <Box flex={"5"} minW={"400px"} overflow={"hidden"} borderRadius={"12px"} height={"600px"} bg="#EEEEEE" padding={"4rem 0 0 4rem"}>
                <Box mb="2.5rem">
                    <Heading mb=".7rem" fontWeight={"600"} color={"#344054"} fontSize={"1.1rem"}>Inventory management</Heading>
                    <Text fontSize={".87rem"} maxW={["200px", "100%"]} color="#475467">Analyze inventory by day, week, brand, store, and state.</Text>
                </Box>
                <Box height={"450px"} bg="#FFF" backgroundImage={Img6} backgroundSize={"cover"} borderRadius={"12px 0 0 0"} />
            </Box>
        </Flex>
        <Flex justifyContent={"center"} mt="4rem">
            <Flex gap={"1rem"}>
                <CustomDrawer
                position={"right"}
                title={"Get started with PromoRama"}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={
                    <PrimaryButton bg="#000000" py=".8rem" width={["170px", "210px"]} height="53px !important" color="#FFF !important" text="Start a campaign" />
                }
                content={
                    <ClientForm setTriggerClose={setTriggerClose} />
                }
                />
                <PrimaryButton width={["110px", "150px"]} bg="#FFF" border="1px solid #D0D5DD" py=".8rem" height="53px !important" color="#000000 !important" text="Learn more" />
            </Flex>
        </Flex>


        <Box mt={["6rem", "9rem"]} mb="3rem" textAlign={"center"}>
            <Heading fontWeight={"500 !important"} maxW={["350px", "500px"]} mx="auto" color={"#344054"} lineHeight={["32px", "45px"]} fontSize={["1.6rem", "2.3rem"]}>All controlled by the enterprise via one dashboard</Heading>
            <Text mb="1.9rem" mt="1rem" fontSize={"1rem"} mx="auto" maxW={["350px", ""]} color="#667085">Seamlessly linking manufacturers, storefronts, sales teams, and enumerators.</Text>
            <Flex justifyContent={"center"}>
                <Core alt={"a"} />
            </Flex>
        </Box>
        <Box maxW={"900px"} mx="auto" px="1rem">
            <Image src={Bg1} />
        </Box>

        <Box mt={["6rem", "9rem"]} mb="3rem" textAlign={"center"}>
            <Heading fontWeight={"500 !important"} maxW={"300px"} mx="auto" color={"#344054"} lineHeight={["32px", "45px"]} fontSize={["1.6rem", "2.3rem"]}>A database of  aggregated stores</Heading>
            <Text mt="1rem" fontSize={"1rem"} color="#667085">List and manage stores effortlessly</Text>
        </Box>
        <Box maxW={"900px"} mx="auto" px="1rem">
            <Image src={Bg2} />
        </Box>

    </Box>
  )
}

export default Features