/* eslint-disable react/prop-types */
import Icon1 from './icons/connect.svg'
import Icon2 from './icons/track.svg'
import Icon3 from './icons/amplify.svg'
import Generic from './icons/generic.svg'
import { Flex, Image, Text } from '@chakra-ui/react'

const Core = ({alt}) => {

  const data = [
    {
        title: "Connect",
        icon: Icon1
    },
    {
        title: "Track",
        icon: Icon2
    },
    {
        title: "Amplify",
        icon: Icon3
    }
  ]

  return (
    <Flex gap={[".7rem", "2rem"]}>
        {data?.map(({title, icon}) => {
            return (
                <Flex gap=".8rem" padding={".6rem 1rem"} key={title} borderRadius={"10px"} border="1px solid #E4E7EC">
                    <Image src={alt ? Generic : icon} />
                    <Text color="#012657" fontWeight={"500"} fontSize={".85rem"}>{title}</Text>
                </Flex>
            )
        })}
    </Flex>
  )
}

export default Core