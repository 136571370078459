import { Box, Flex, Heading, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import PrimaryButton from '../../components/primaryButton/primarybutton';
import CustomInput from '../../components/customInput/customInput';
import AuthBg from '../../assets/bg-element.svg'
import CustomTextArea from '../../components/customTextArea/customTextArea';
import axios from 'axios'

const ContactUs = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

  
    const toast = useToast();
  
    const datax = {
      email,
      name,
      message
    };
  
    const contact = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const { data } = await axios.post('https://promorama-api.onrender.com/api/v1/support/contact-us', datax);
        toast({
            description: data?.message,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
      } catch (error) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Flex height={"80vh"} flexWrap={"wrap"} pt="75px">
        <Box flex={"1 1 400px"}bg="#0C0C0C" backgroundImage={AuthBg} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}></Box>
        <Flex flex={"1 1 400px"} p={["1.5rem 1rem", "5.5rem 4rem"]} bg="#F5F5F5" alignItems={"center"} justifyContent={"center"}>
          <Box
            padding={["2rem .7rem 1.5rem .7rem", "4rem 2rem 1.5rem 2rem"]}
            bg="#FFF"
            width={"100%"}
            height={"100%"}
            boxShadow={"0px 4px 24px rgba(0, 0, 0, 0.16)"}
            borderRadius={"8px"}
            textAlign={"center"}
            border="0.5px solid #D0D5DD"
          >
            <Heading
              mt={6}
              fontWeight={"600"}
              fontSize={["1.35rem", "1.5rem"]}
              color="#27272A"
            >
              Contact Us
            </Heading>
            <Text mt={2} mb="1rem" color={"#3F3F46"} fontSize={".9rem"}>
            Reach out to us and we’ll get back to you
            </Text>
            <form onSubmit={contact}>
              <CustomInput
                placeholder={"Name"}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <CustomInput
                placeholder={"Email Address"}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value?.toLowerCase())}
              />
  
              <CustomTextArea
                placeholder={"Enter Message"}
                value={message}
                rows="6"
                onChange={(e) => setMessage(e.target.value)}
              />
              
              <PrimaryButton mt="3rem" type="submit" text="Send a message" loading={loading} />
              
            </form>
          </Box>
        </Flex>
      </Flex>
    );
}

export default ContactUs