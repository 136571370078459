import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import Icon1 from './assets/icon1.svg'
import Img1 from './assets/img1.png'
import Img2 from './assets/img2.png'
import Img3 from './assets/img3.png'
import PrimaryButton from "../../../../components/primaryButton/primarybutton"
import { useNavigate } from "react-router-dom"

const How = () => {
  const navigate = useNavigate()
  return (
    <Box px={"1rem"} maxW="1200px" mx="auto" mt={["8rem", "11rem"]}>
        <Box textAlign={"center"} mb="4.5rem">
            <Flex mb={["1.2rem", "1.7rem"]} padding={".53rem 1.5rem"} gap=".5rem" bg="#FFF" width={"fit-content"} mx="auto" borderRadius={"56px"} border="1px solid #D0D5DD">
                <Text fontSize={".85rem"} color="#475467">HOW IT WORKS</Text>
                <Image src={Icon1} />
            </Flex>
            <Heading fontWeight={"500 !important"} maxW={["350px", "500px"]} mx="auto" color={"#344054"} lineHeight={["32px","37px"]} fontSize={["1.5rem", "1.8rem"]}>Campaigns are executed by a super-team of store owners, promoters & supervisors and you control it all</Heading>
            <Text maxW="350px" mx="auto" mt="1rem" fontSize={"1rem"} color="#667085">This network of individuals is guaranteed to help you sell your products 10x better.</Text>
        </Box>

        <Flex gap={"3rem"} mb={["1rem", "6rem"]} flexWrap={"wrap"}>
            <Box flex={"1"} bg="#FFF" minWidth={"400px"} borderRadius={"12px"} height={"590px"} backgroundImage={Img1} backgroundSize={"cover"} />
            <Box flex={"1"} height={["380px", "580px"]}>
                <Heading fontWeight={"500 !important"} maxW={"370px"} color={"#344054"} lineHeight={"30px"} fontSize={["1.2rem", "1.5rem"]}>The store manager keeps an eye on all campaigns in store</Heading>
                <Text maxW="350px" mt=".4rem" fontSize={[".85rem", ".95rem"]} color="#667085">Subtitle about the store manager</Text>
                <Text mb="4rem" maxW="500px" bg="#FFF" padding={"1rem 1.5rem"} mt="2rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>The store will automatically assign a team to handle this campaign for the number of days specified</Text>
                <PrimaryButton onClick={() => navigate('/store-managers')} bg="#000000" py=".8rem" width="165px" height="53px !important" color="#FFF !important" text="Learn more" />
            </Box>
        </Flex>

        <Flex display={["none", "flex"]} gap="3rem" mb={["1rem", "6rem"]} flexWrap={"wrap"}>
            <Box flex={"1"} height={"580px"}>
                <Heading fontWeight={"500 !important"} maxW={"370px"} color={"#344054"} lineHeight={"30px"} fontSize={["1.2rem", "1.5rem"]}>The promoter ensures campaign sales targets are met</Heading>
                <Text maxW="350px" mt=".4rem" fontSize={[".85rem", ".95rem"]} color="#667085">Subtitle about the promoter</Text>
                <Text maxW="500px" bg="#FFF" padding={"1.5rem"} mt="2rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>Promoters handle in-store and out-store activities.</Text>
                <Text mb="4rem" maxW="500px" bg="#FFF" padding={"1.5rem"} mt="1rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>They also ensure that items are sold and recorded</Text>
                <PrimaryButton onClick={() => navigate('/promoters')} bg="#000000" py=".8rem" width="165px" height="53px !important" color="#FFF !important" text="Learn more" />
            </Box>
            <Box flex="1" bg="#FFF" minWidth={"400px"} borderRadius={"12px"} height={"590px"} backgroundImage={Img2} backgroundSize={"cover"} />
        </Flex>
        <Flex display={["flex", "none"]} gap="3rem" mb={["1rem", "6rem"]} flexWrap={"wrap"}>
            <Box flex="1" bg="#FFF" minWidth={"400px"} borderRadius={"12px"} height={"590px"} backgroundImage={Img2} backgroundSize={"cover"} />
            <Box flex={"1"} height={["480px", "580px"]}>
                <Heading fontWeight={"500 !important"} maxW={"370px"} color={"#344054"} lineHeight={"30px"} fontSize={["1.2rem", "1.5rem"]}>The promoter ensures campaign sales targets are met</Heading>
                <Text maxW="350px" mt=".4rem" fontSize={[".85rem", ".95rem"]} color="#667085">Subtitle about the promoter</Text>
                <Text maxW="500px" bg="#FFF" padding={"1.5rem"} mt="2rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>Promoters handle in-store and out-store activities.</Text>
                <Text mb="4rem" maxW="500px" bg="#FFF" padding={"1.5rem"} mt="1rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>They also ensure that items are sold and recorded</Text>
                <PrimaryButton onClick={() => navigate('/promoters')} bg="#000000" py=".8rem" width="165px" height="53px !important" color="#FFF !important" text="Learn more" />
            </Box>
        </Flex>

        <Flex gap="3rem" flexWrap={"wrap"}>
            <Box flex="1" bg="#FFF" minWidth={"400px"} borderRadius={"12px"} height={"590px"} backgroundImage={Img3} backgroundSize={"cover"} />
            <Box flex={"1"} height={["380px", "580px"]}>
                <Heading fontWeight={"500 !important"} maxW={"370px"} color={"#344054"} lineHeight={"30px"} fontSize={["1.2rem", "1.5rem"]}>The supervisor is assigned a set of promoters to be monitored</Heading>
                <Text maxW="350px" mt=".4rem" fontSize={[".85rem", ".95rem"]} color="#667085">Subtitle about the supervisor</Text>
                <Text mb="4rem" maxW="500px" bg="#FFF" padding={"1rem 1.5rem"} mt="2rem" borderLeft={"1px solid #000"} borderRadius={"8px"} fontSize={[".85rem", ".95rem"]} color="#667085" boxShadow={"0px 1.5px 4px -1px #10192812"}>The store will automatically assign a team to handle this campaign for the number of days specified</Text>
                <PrimaryButton onClick={() => navigate('/supervisors')} bg="#000000" py=".8rem" width="165px" height="53px !important" color="#FFF !important" text="Learn more" />
            </Box>
        </Flex>
    </Box>
  )
}

export default How
