import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

const data = [
    {
        question: "What is promorama?",
        answer: "Helping your brand 10x sales with our diverse network of stores and integrated management tools."
    },
    {
        question: "How do I start using promorama?",
        answer: "Helping your brand 10x sales with our diverse network of stores and integrated management tools."
    },
    {
        question: "How do I start using promorama?",
        answer: "Helping your brand 10x sales with our diverse network of stores and integrated management tools."
    },
    {
        question: "How do I start using promorama?",
        answer: "Helping your brand 10x sales with our diverse network of stores and integrated management tools."
    },
    {
        question: "How do I start using promorama?",
        answer: "Helping your brand 10x sales with our diverse network of stores and integrated management tools."
    },
    {
        question: "How do I start using promorama?",
        answer: "Helping your brand 10x sales with our diverse network of stores and integrated management tools."
    },
]

const Faq = () => {
  return (
    <Box
      px={["1rem", "3rem", "6rem"]}
      bg="#000000"
      pt="1.5rem"
    >
      <Box maxW={"800px"} mx="auto">

      <Accordion allowToggle allowMultiple={false} defaultIndex={[0]}>
      {data?.map(({question, answer}, index) => {
        return (
                <AccordionItem key={index} borderColor={"#E4E7EC"} border="none" borderBottom=".5px solid ">
                <h2>
                    <AccordionButton padding={".6rem 0"}>
                    <Box
                        color={"#E4E7EC"}
                        fontWeight="400"
                        fontSize={["1rem", "1.25rem"]}
                        padding={".6rem 0"}
                        as="span"
                        flex="1"
                        textAlign="left"
                        borderTop={"none"}
                        _expanded={{fontWeight: "500"}}
                    >
                        {question}
                    </Box>
                    <AccordionIcon color={"#E4E7EC"} />
                    </AccordionButton>
                </h2>
                <AccordionPanel
                    color={"#D0D5DD"}
                    fontSize={[".9rem", "1rem"]}
                    pb={4}
                    padding={".7rem 0 1.3rem 0"}
                    maxW="85%"
                >
                    {answer}
                </AccordionPanel>
                </AccordionItem>
        )
      })}
      </Accordion>
      </Box>
    </Box>
  );
};

export default Faq;
