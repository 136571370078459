import Ios from './assets/ios.svg'
import Android from './assets/android.svg'
import { Box, Flex, Image, Text } from '@chakra-ui/react'

const Download = () => {
  return (
    <Box maxW={"500px"} mx="auto" mt="3rem">
        <Text textAlign={"center"} fontWeight={"500"} fontSize={"1.25rem"}>Download the PromoRama App</Text>
        <Flex justifyContent={"center"}>
            <Flex gap="1rem" mt="1.2rem">
                <Flex cursor={"pointer"} alignItems={"center"} width={"160px"} gap=".8rem" padding={".6rem 1rem"} borderRadius={"10px"} border="1px solid #1D2939">
                    <Image src={Ios} />
                    <Text color="#012657" fontWeight={"600"} fontSize={".9rem"}>IOS App</Text>
                </Flex>
                <Flex cursor={"pointer"} alignItems={"center"} width={"160px"} gap=".8rem" padding={".6rem 1rem"} borderRadius={"10px"} border="1px solid #1D2939">
                    <Image src={Android} />
                    <Text color="#012657" fontWeight={"600"} fontSize={".9rem"}>Android App</Text>
                </Flex>
            </Flex>
        </Flex>
    </Box>
  )
}

export default Download