/* eslint-disable react/prop-types */
import { Button, Spinner, Text } from "@chakra-ui/react"

const PrimaryButton = (props) => {
    return (
        <Button
        backgroundColor={props?.bg || "#101828"}
        fontSize=".85rem"
        fontWeight={"500"}
        fontFamily={"Inter"}
        borderRadius={"6px"}
        padding="1.65rem 1.5rem"
        width={"100%"}
        _hover={{ color: "#000"}}
        transition={"250ms ease"}
        _disabled={{backgroundColor: "#D0D5DD", cursor: "not-allowed"}}
        {...props}
        >
       {props.loading === true ? 
       <Spinner color="#FFF" />
       : 
       <Text fontSize={".85rem"} color={props?.color || "#FFFFFF"}>
        {props?.text}
       </Text>
       }
       </Button>
    )
}

export default PrimaryButton